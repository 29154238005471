import React from 'react';
import logo from './assets/logo.jpg';
import './App.css';
import {Container, Row, Col} from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';

function App() {
  return (
    <div className="App">
      <Container fluid className="coming-soon-background">
        <Row> 
          <Col>
            <img src={logo} className="app-logo" alt="coming soon" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} md={8} xl={5} className="coming-soon-container">
            <Row className="content"> 
              <h4><strong>YOU CAUGHT US!</strong></h4>
              <br/><br/>
              <div className="content-style"><h5>Our site is under development,  
we’ll be up and running soon.<br/><br/></h5>
<h5>
 
In the mean time checkout some aesthetic home decor items on our social media handles.</h5>
<br/>
</div></Row>    
<Row className="justify-content-center">
          <SocialIcon url="https://www.facebook.com/Navikahomedecormosaiclamps/" />&nbsp;&nbsp;
          <SocialIcon url="https://www.instagram.com/navika_home_decor/" />&nbsp;&nbsp;
          <SocialIcon url="https://lbb.in/shop/m/navika-home-decor-1358163228151" />&nbsp;&nbsp;
          <SocialIcon url="https://twitter.com/" />
        </Row>   
          </Col>
        </Row>
           


     
       

      </Container>
      {/* <header className="App-header">
        <img src={comingSoonBackground} className="App-logo" alt="coming soon" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>

  );
}

export default App;
